import React from "react"
import Layout from "../components/layout"
import indexStyles from "./index.module.css"
import Content_surround from "../components/content_surround"

export default function Portfolio() {
  return (
    <Layout image="https://admhotels.com/images/hotel_lobby_1.jpeg" page_title="Portfolio">
      <div className={indexStyles.wrapper}> 
        <div className="stdSection">
        <h2 className="text-center">Please Select a Project to Learn More:</h2>
        <a href="/blackthorn" style={{textDecoration:'none',color:'black'}}>
          <Content_surround goTo="">
            <p className="text-center" style={{color:'goldenrod',fontWeight:'bold',fontSize:'20px'}} >Blackthorn Hotel & Golf Resort</p>            
            <p className="text-center" style={{fontSize:'20px'}}>South Bend, IN</p>
            <p style={{color:'limegreen', fontWeight:'bold'}} className="text-center">(Full project/development budget of One Billion US Dollars ($1,000,000,000) under investment and available by Joint Venture and Equity Partnership)</p>
            <p className="text-center" style={{textDecoration:'underline', color:'blue'}}>Click Here to Learn More About the Project...</p>
          </Content_surround> 
        </a>
          <Content_surround goTo="">
            <p className="text-center" style={{color:'limegreen',fontWeight:'bold',fontSize:'20px'}}>- COMING SOON -</p>
            <p className="text-center" style={{color:'goldenrod',fontWeight:'bold',fontSize:'20px'}} >Lakeside South Chicago Development</p>            
            {/*<p className="text-center" style={{fontSize:'20px'}}>8080 S. Lakeshore Drive, Chicago, IL 60617</p>*/}
            <p className="text-center"  >(PROJECT DETAILS ARE IN PROGRESS, PLEASE CHECK BACK LATER)</p>
          </Content_surround> 

        </div>
      </div>
    </Layout>



  ) 
}
